// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-us-js": () => import("./../../../src/pages/404.en-US.js" /* webpackChunkName: "component---src-pages-404-en-us-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-zh-cn-js": () => import("./../../../src/pages/404.zh-CN.js" /* webpackChunkName: "component---src-pages-404-zh-cn-js" */),
  "component---src-pages-career-en-us-js": () => import("./../../../src/pages/career.en-US.js" /* webpackChunkName: "component---src-pages-career-en-us-js" */),
  "component---src-pages-career-zh-cn-js": () => import("./../../../src/pages/career.zh-CN.js" /* webpackChunkName: "component---src-pages-career-zh-cn-js" */),
  "component---src-pages-crm-en-us-js": () => import("./../../../src/pages/crm.en-US.js" /* webpackChunkName: "component---src-pages-crm-en-us-js" */),
  "component---src-pages-crm-zh-cn-js": () => import("./../../../src/pages/crm.zh-CN.js" /* webpackChunkName: "component---src-pages-crm-zh-cn-js" */),
  "component---src-pages-index-en-us-js": () => import("./../../../src/pages/index.en-US.js" /* webpackChunkName: "component---src-pages-index-en-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-zh-cn-js": () => import("./../../../src/pages/index.zh-CN.js" /* webpackChunkName: "component---src-pages-index-zh-cn-js" */),
  "component---src-pages-news-en-us-js": () => import("./../../../src/pages/news.en-US.js" /* webpackChunkName: "component---src-pages-news-en-us-js" */),
  "component---src-pages-news-zh-cn-js": () => import("./../../../src/pages/news.zh-CN.js" /* webpackChunkName: "component---src-pages-news-zh-cn-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-career-post-jsx": () => import("./../../../src/templates/career-post.jsx" /* webpackChunkName: "component---src-templates-career-post-jsx" */),
  "component---src-templates-news-article-jsx": () => import("./../../../src/templates/news-article.jsx" /* webpackChunkName: "component---src-templates-news-article-jsx" */),
  "component---src-templates-product-product-jsx": () => import("./../../../src/templates/product-product.jsx" /* webpackChunkName: "component---src-templates-product-product-jsx" */),
  "component---src-templates-product-series-jsx": () => import("./../../../src/templates/product-series.jsx" /* webpackChunkName: "component---src-templates-product-series-jsx" */)
}

