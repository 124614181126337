import "./src/styles/index.css";

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
    }
  }

export const shouldUpdateScroll = ({}) => {
    window.scrollTo(...([0, 0]))
    return true
  }

//   exports.shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition
//   }) => {
  
//     if (location.hash) {
//       return false;
//     }
  
//     return true;
// };